let isMobile = false;
if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
    isMobile = true;
}

let token = document.querySelector("head meta[name='csrf-token']").getAttribute("content");
let lang = document.documentElement.lang;

if (document.querySelectorAll("#btn-go-top").length) {


    let mybutton = document.querySelector("#btn-go-top");
    window.onscroll = function () {
        scrollFunction()
    };

    function scrollFunction() {
        if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
            mybutton.style.display = "block";
        } else {
            mybutton.style.display = "none";
        }
    }

    {
        document.querySelector("#btn-go-top").addEventListener("click", (e) => {
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: "smooth",
                passive: true,
            });
        });
    }
}

function checkMobile() {
    if (isMobile) {
        document.querySelector("body").classList.add("is--mobile");
        document.querySelector("body").classList.remove("is--desktop");
    } else {
        document.querySelector("body").classList.remove("is--mobile");
        document.querySelector("body").classList.add("is--desktop");
    }
}

checkMobile();

function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 && rect.left >= 0 &&
        // rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

let swiperScript = 0;


if (document.querySelectorAll(".header").length) {
    var navbar = document.querySelectorAll(".header")[0];

    function addFixedMenu() {
        if (window.pageYOffset >= 50) {
            navbar.classList.add("fixed-header-scroll");
            document.querySelector("body").classList.add("menu-is-fixed");
        } else {
            navbar.classList.remove("fixed-header-scroll");
            document.querySelector("body").classList.remove("menu-is-fixed");
        }
    }

    if (document.body.clientHeight > (window.innerHeight + 400)) {
        addFixedMenu();
        window.addEventListener("scroll", function (e) {
            addFixedMenu();
        });
    }
}


window.slideUp = (target, duration = 500) => {

    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout(() => {
        target.style.display = 'none';
        target.style.removeProperty('height');
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
        //alert("!");
    }, duration);
}

/* SLIDE DOWN */
window.slideDown = (target, duration = 500) => {

    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;
    if (display === 'none') display = 'block';
    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    window.setTimeout(() => {
        target.style.removeProperty('height');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
    }, duration);
}

/* TOOGLE */
var slideToggle = (target, duration = 500) => {
    if (window.getComputedStyle(target).display === 'none') {
        return slideDown(target, duration);
    } else {
        return slideUp(target, duration);
    }
}

const navItemToOpenA = document.querySelectorAll('.nav__level-1>.menu__item--has-children>a');
const navItemToOpenLi = document.querySelectorAll('.nav__level-1>.menu__item--has-children');

const navItemToOpenA2 = document.querySelectorAll('.nav__level-2>li>a');
const navItemToOpenLi2 = document.querySelectorAll('.nav__level-2>li');
let loginBtn = document.querySelector(".login__btn");
let loginWrapper = document.querySelector(".login__wrapper");
let loginList = document.querySelector(".login__list");

let formSearchH = document.querySelector(".headerForm");
let wrapperSearchH = document.getElementById("search-mobile-close");
let searchInput = document.getElementById("mainSearchInput");
let alertAddToCart = document.getElementById("alert-add-to-cart");


function CloseLoginMobile() {
    if (loginBtn.classList.contains("open")) {
        loginList.classList.remove("open");
        loginBtn.classList.remove("open");
    }
}


document.querySelector("#nav-toggle").addEventListener("click", function (e) {
    e.preventDefault();
    e.stopPropagation();

    this.classList.toggle("open");
    if (this.classList.contains("open")) {

        document.querySelector("body").classList.add("menu-open");
        document.querySelector(".nav__wrapper").classList.add("open");


    } else {

        if (document.querySelector(".nav ul.open")) {
            document.querySelector(".nav__wrapper").classList.remove("open");
        }
        document.querySelector(".nav__wrapper").classList.remove("open");
        document.querySelector("body").classList.remove("menu-open");
    }
    e.preventDefault();
    e.stopPropagation();


})


var menuItems = document.querySelectorAll('li.menu__item--has-children>a');

Array.prototype.forEach.call(menuItems, function (el, i) {
    let parent = el.closest(".menu__item--has-children");
    let last = el.closest(".menu__item--has-children").querySelector("li:last-child>a");
    el.addEventListener("focus", function (event) {
        parent.className = "menu__item--has-children open";
    });
    last.addEventListener("focusout", function (event) {
        document.querySelector(".menu__item--has-children.open").className = "menu__item--has-children";

    });
});

let initialImgMenuActive = 0;

function initiaImgMenu() {
    if (initialImgMenuActive == 0) {
        initialImgMenuActive = 1;
        let allImg = document.querySelectorAll(".nav a img");
        for (let i = 0; i < allImg.length; i++) {
            if (allImg[i].hasAttribute("data-src")) {
                let newSrc = allImg[i].getAttribute("data-src");
                allImg[i].setAttribute("src", newSrc);
            }
        }
    }
}


for (var i = 0; i < navItemToOpenA.length; i++) {
    navItemToOpenLi[i].addEventListener("mouseenter", function (e) {
        if (initialImgMenuActive == 0) {
            initiaImgMenu();
        }
    });
}

if (window.ontouchstart !== undefined) {
    document.querySelector("body").classList.add("menu-mobile");

    for (var i = 0; i < navItemToOpenA.length; i++) {
        navItemToOpenLi[i].addEventListener("click", function (e) {
            if (initialImgMenuActive == 0) {
                initiaImgMenu();
            }
            // e.preventDefault();
            // e.stopPropagation();
            let submenu = this.querySelector('ul');

            if (submenu.classList.contains("m-open")) {
                slideUp(submenu, 300);
                submenu.classList.remove("m-open");
            } else {
                if (document.querySelector(".nav ul.m-open")) {
                    slideUp(document.querySelector(".nav ul.m-open"), 300);
                    document.querySelector(".nav ul.m-open").classList.remove("open");
                }
                slideDown(submenu, 300);
                submenu.classList.add("m-open");
            }
            if (document.querySelector(".nav ul.m2-open")) {
                slideUp(document.querySelector(".nav ul.m2-open"), 300);
                document.querySelector(".nav ul.m2-open").classList.remove("m2-open");
            }

        });
    }
    for (var i = 0; i < navItemToOpenA2.length; i++) {
        navItemToOpenLi2[i].addEventListener("click", function (e) {
            e.stopPropagation();

            let submenu = this.parentNode.querySelector(".nav__level-3");

            if (submenu.classList.contains("m2-open")) {
                slideUp(submenu, 300);
                submenu.classList.remove("m2-open");
            } else {
                if (document.querySelector(".nav ul.m2-open")) {
                    slideUp(document.querySelector(".nav ul.m2-open"), 300);
                    document.querySelector(".nav ul.m2-open").classList.remove("m2-open");
                }
                slideDown(submenu, 300);
                submenu.classList.add("m2-open");
            }
        });
    }

}


//accordion
function accordion(accordion) {
    let accordionContent = accordion.querySelector(".accordion__content");
    let accordionBtn = accordion.querySelector(".accordion__trigger");
    accordionBtn.addEventListener("click", function (e) {

        e.preventDefault();
        if (accordionBtn.getAttribute("aria-expanded") == "true") {

            accordionBtn.setAttribute('aria-expanded', false);
            accordionContent.setAttribute('aria-hidden', true);
            if (accordion.classList.contains("active")) {
                accordion.classList.remove("active");
            }
            slideUp(accordionContent);

        } else {
            accordionBtn.setAttribute('aria-expanded', true);
            accordionContent.setAttribute('aria-hidden', false);
            accordion.classList.add("active");
            slideDown(accordionContent);
        }
    })
}

Array.prototype.map.call(document.querySelectorAll(".accordion"), function (item) {
    accordion(item);
});


//TAB
function tabsRun(wrapper) {
    var tabs = wrapper.querySelectorAll('[role=tab]'); //get all role=tab elements as a variable
    var tabList = wrapper.querySelector('[role=tablist]'); //get all role=tab elements as a variable
    for (i = 0; i < tabs.length; i++) {
        tabs[i].addEventListener("click", showTabPanel);
    } //add click event to each tab to run the showTabPanel function

    let tabFocus = 0;
    tabList.addEventListener("keydown", (e) => {
        // Move right
        if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
            tabs[tabFocus].setAttribute("tabindex", -1);
            if (e.key === "ArrowRight") {
                tabFocus++;
                // If we're at the end, go to the start
                if (tabFocus >= tabs.length) {
                    tabFocus = 0;
                }
                // Move left
            } else if (e.key === "ArrowLeft") {
                tabFocus--;
                // If we're at the start, move to the end
                if (tabFocus < 0) {
                    tabFocus = tabs.length - 1;
                }
            }
            tabs[tabFocus].setAttribute("tabindex", 0);
            tabs[tabFocus].focus();
        }
    });

    function showTabPanel(el) { //runs when tab is clicked
        var tabs2 = wrapper.querySelectorAll('[role=tab]'); //get tabs again as a different variable
        for (i = 0; i < tabs2.length; i++) {
            tabs2[i].setAttribute('aria-selected', 'false');

        } //reset all tabs to aria-selected=false and normal font weight
        el.target.setAttribute('aria-selected', 'true'); //set aria-selected=true for clicked tab
        var tabPanelToOpen = el.target.getAttribute('aria-controls');
        if (!tabPanelToOpen) {

            tabPanelToOpen = el.target.close("button").getAttribute('aria-controls');
        } //get the aria-controls value of the tab that was clicked
        var tabPanels = wrapper.querySelectorAll('[role=tabpanel]'); //get all tabpanels as a variable
        for (i = 0; i < tabPanels.length; i++) {
            tabPanels[i].setAttribute("aria-hidden", "true");
            tabPanels[i].classList.remove("is-current");
        } //hide all tabpanels

        wrapper.querySelector("#" + tabPanelToOpen).setAttribute("aria-hidden", "false");
        wrapper.querySelector("#" + tabPanelToOpen).classList.add("is-current"); //show tabpanel who's tab was clicked
    }
}

Array.prototype.map.call(document.querySelectorAll(".tabs"), function (item) {
    tabsRun(item)
});


let touchstartX = 0;
let touchendX = 0;

if (document.querySelector('.elements__right')) {
    const gestureZone = document.querySelector('.elements__right');


    gestureZone.addEventListener('touchstart', function (event) {
        touchstartX = event.changedTouches[0].screenX;
    }, false);

    gestureZone.addEventListener('touchend', function (event) {
        touchendX = event.changedTouches[0].screenX;
        handleGesture();
    }, false);
}

function handleGesture() {
    if (touchendX >= touchstartX) {
        if (document.querySelector(".elements [aria-selected=true]").parentNode.previousElementSibling) {
            document.querySelector(".elements [aria-selected=true]").parentNode.previousElementSibling.querySelector("button").click();
        }
    }
    if (touchendX <= touchstartX) {
        if (document.querySelector(".elements [aria-selected=true]").parentNode.nextElementSibling) {
            document.querySelector(".elements [aria-selected=true]").parentNode.nextElementSibling.querySelector("button").click()
        }
    }

};


(function () {
    /* Opening modal window function */
    function openModal() {
        /* Get trigger element */
        var modalTrigger = document.getElementsByClassName('jsModalTrigger');

        /* Set onclick event handler for all trigger elements */
        for (var i = 0; i < modalTrigger.length; i++) {

            modalTrigger[i].onclick = function (e) {
                e.preventDefault();
                var target = this.getAttribute('data-href');
                var modalWindow = document.getElementById(target);

                modalWindow.classList ? modalWindow.classList.add('open') : modalWindow.className += ' ' + 'open';
            }
        }
    }

    function closeModal() {
        /* Get close button */
        var closeButton = document.getElementsByClassName('jsModalClose');
        var closeOverlay = document.getElementsByClassName('jsOverlay');

        /* Set onclick event handler for close buttons */
        for (var i = 0; i < closeButton.length; i++) {
            closeButton[i].onclick = function () {
                var modalWindow = this.parentNode.parentNode;

                modalWindow.classList ? modalWindow.classList.remove('open') : modalWindow.className = modalWindow.className.replace(new RegExp('(^|\\b)' + 'open'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }

        /* Set onclick event handler for modal overlay */
        for (var i = 0; i < closeOverlay.length; i++) {
            closeOverlay[i].onclick = function () {
                var modalWindow = this.parentNode;

                modalWindow.classList ? modalWindow.classList.remove('open') : modalWindow.className = modalWindow.className.replace(new RegExp('(^|\\b)' + 'open'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }

    }

    /* Handling domready event IE9+ */
    function ready(fn) {
        if (document.readyState != 'loading') {
            fn();
        } else {
            document.addEventListener('DOMContentLoaded', fn);
        }
    }

    /* Triggering modal window function after dom ready */
    ready(openModal);
    ready(closeModal);


}());


if (document.getElementById("hero__move")) {

    let heroLeft = (document.querySelector(".hero-clipping-wrapper").getBoundingClientRect()).left;
    let heroRight = document.querySelector(".hero__right").clientWidth;


    if (window.innerWidth > 576) {
        document.querySelector(".hero-clipping-wrapper").style.width = "50%";
    } else {
        document.querySelector(".hero-clipping-wrapper").style.width = "100px";
    }

    let isDown = false;
    let offsetLeft = 0;

    document.querySelector("#hero__move").addEventListener("mousedown", function (event) {
        isDown = true;
        offsetLeft = event.pageX - heroLeft;

    }, true);
    document.addEventListener('mouseup', function () {
        isDown = false;
    }, true);
    document.addEventListener('mousemove', function (event) {
        event.preventDefault();
        if (isDown) {
            offsetLeft = event.pageX - heroLeft;
            if (offsetLeft > 0 && offsetLeft < heroRight) {
                document.querySelector(".hero-clipping-wrapper").style.width = offsetLeft + "px";
            }
        }
    }, true);

    document.querySelector(".hero__right").addEventListener("touchmove", function (event) {
        offsetLeft = event.touches[0].clientX;
        document.querySelector(".hero-clipping-wrapper").style.width = offsetLeft + "px";

    });


    // document.querySelector("#hero__move").addEventListener("touchmove", function(event) {
    //     pointerX = event.touches[0].clientX - heroLeft;;
    //     pointerY = event.pageY;
    //     if (pointerX > 0) { document.querySelector(".hero-clipping-wrapper").style.width = pointerX + "px"; }

    // });


}


//ulubione

var http_requestX = false;

/*
data - dane do wysłania
callbackF - funkcja zwrotna
itemEl - elemnt na kórym wywołujemy funkcje zwrotną /opcjonalny
*/
function makeRequest(url = '/a/', method = 'POST', data, callbackF, itemEl) {

    http_requestX = false;

    if (window.XMLHttpRequest) { // Mozilla, Safari,...
        http_requestX = new XMLHttpRequest();
        if (http_requestX.overrideMimeType) {
            http_requestX.overrideMimeType('text/xml');
            // Przeczytaj o tym wierszu poniżej
        }
    } else if (window.ActiveXObject) { // IE
        try {
            http_requestX = new ActiveXObject("Msxml2.XMLHTTP");
        } catch (e) {
            try {
                http_requestX = new ActiveXObject("Microsoft.XMLHTTP");
            } catch (e) {
            }
        }
    }

    if (!http_requestX) {
        alert('Nie mogę stworzyć instancji obiektu XMLHTTP');
        return false;
    }

    http_requestX.onreadystatechange = function () {
        alertContents2(http_requestX, callbackF, itemEl)
    };
    http_requestX.open(method, url, true);
    http_requestX.send(data);
}

function alertContents2(http_requestX, functionName, itemEl) {
    try {
        if (http_requestX.readyState === 4) {
            if (http_requestX.status == 200) {


                functionName(http_requestX.responseText, itemEl);


            } else {

                alert('Wystąpił problem z tym żądaniem.');
            }
        }
    } catch (e) {

    }
}

function favouriteCountCallbackFunction(data) {

    let heart = document.querySelector(".headerControls__heart .header__cartAmount");
    let heart2 = document.querySelector(".headerControls__heart");
    heart.innerHTML = data;


    (data > 0) ? heart2.classList.add('is-active') : heart2.classList.remove('is-active');
    (data > 0) ? heart.innerHTML = data : heart.innerHTML = "0";

    if (!data > 0 && document.getElementById("favourte--info")) {
        document.getElementById("favourte--info").style.display = "block";
    }

}


function favouriteCallbackFunction(data, el) {

    if (data == "ok") {

        el.classList.toggle("btn--heartAvtive");
        if (el.querySelector("span")) {
            let txtEls = el.querySelectorAll("span");

            txtEls[0].classList.toggle("hide");
            txtEls[1].classList.toggle("hide");
        }
    }

    dataToSend = new FormData();
    dataToSend.set('a', 'ulubione-ile');
    dataToSend.set('__csrf__token__', token);
    makeRequest('/a/?a=ulubione-ile', "GET", dataToSend, favouriteCountCallbackFunction);
}

function favouriteAdd(el) {
    el.addEventListener("click", function (e) {
        e.preventDefault();
        let id = el.getAttribute("data-id");

        let action = "ulubione-dodaj";
        el.setAttribute("aria-label", "Usuń z ulubionych");
        if (el.classList.contains("btn--heartAvtive")) {
            action = "ulubione-usun";
            el.setAttribute("aria-label", "Dodaj do ulubionych");
        }

        data = new FormData();
        data.set('a', action);
        data.set('produkt', id);
        data.set('__csrf__token__', token);
        makeRequest('/a/', "POST", data, favouriteCallbackFunction, el);

        if (el.classList.contains("js--removeProduct")) {
            el.closest(".product__item").remove();
        }

    })
}


Array.prototype.map.call(document.querySelectorAll(".btn--heart"), function (item) {
    favouriteAdd(item)
});


//Newsletter: uzupełnienie wartości inputa w modalu

if (document.getElementById("newsletterBtn")) {
    function newsletterCallbackFunction(data) {
        document.getElementById("checbox-ajax").innerText = data;

    }

    document.getElementById("newsletterBtn").addEventListener("click", function () {
        document.getElementById("newsletterEmailModal").value = document.getElementById("newsletterEmail").value;
        if (!this.classList.contains("j2--click")) {
            this.classList.add("j2--click");
            data = new FormData();

            data.set('__csrf__token__', token);
            makeRequest('/a/?a=newsletter-zgoda', "GET", data, newsletterCallbackFunction);

        }

    })


    document.getElementById("newsletterEmail").addEventListener("keypress", function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            document.getElementById("newsletterBtn").click();
        }
    })

}


function cartBoxClose(btn) {

    document.querySelector(".header__cart-box").classList.remove("open");
    btn.classList.remove("active");

    document.querySelector("#box-close-cart").removeEventListener("click", function () {
    }, false)
    document.querySelector("#box-close-cart").remove();

    document.querySelector("body").classList.remove("overflow--hidden");
    document.querySelector(".header__cart-box .alert-success").style.display = "none";
}

function cartBoxOpen() {
    let cartBtn = document.getElementById("headerControls__cartBtn");
    document.querySelector(".header__cart-box").classList.add("open");
    cartBtn.classList.add("active");
    if (!document.querySelector("#box-close-cart")) {
        document.querySelector("body").classList.add("overflow--hidden");
        let box = document.createElement("span");
        box.className = "open-menu-shadow";
        box.id = ("box-close-cart");
        document.querySelector("body").appendChild(box);
        document.querySelector("#box-close-cart").addEventListener("click", function () {
            cartBoxClose(cartBtn);
        })
    }
}

if (document.getElementById("headerControls__cartBtn")) {

    let cartBtn = document.getElementById("headerControls__cartBtn");
    cartBtn.addEventListener("click", function (e) {
        e.preventDefault();

        if (!cartBtn.classList.contains("afterFirstClick")) {
            cartBtn.classList.add("afterFirstClick");
            createCartHeaderList();
        }

        if (cartBtn.classList.contains("active")) {
            cartBoxClose(cartBtn);
        } else {
            alertAddToCart.style.display = "none";
            cartBoxOpen();
            CloseLoginMobile()

        }
        if (document.querySelector(".nav-toggle").classList.contains("open")) {
            document.querySelector(".nav-toggle").click();
        }
    })


    document.querySelector(".btn-close-cart").addEventListener("click", function () {
        cartBoxClose(cartBtn);
    })
    document.querySelector(".btn-close-cart2").addEventListener("click", function () {
        cartBoxClose(cartBtn);
    })


}


//rozwijana lista produktów
if (document.querySelector(".listProduct__btn")) {
    document.querySelector(".listProduct__btn").addEventListener("click", function () {
        if (this.classList.contains("open")) {
            this.classList.remove("open");
            slideUp(document.getElementById("form__nav-left"));
        } else {
            this.classList.add("open");
            slideDown(document.getElementById("form__nav-left"));
        }
        const spans = this.querySelectorAll("span");
        for (var i = 0; i < spans.length; i++) {
            spans[i].classList.toggle("show");

        }
    })
}


if (document.getElementById("charity-own")) {

    let inputFrom = document.getElementById("charity-own");
    let inputTo = document.getElementById("charity-5");
    inputFrom.addEventListener("input", function () {

        inputTo.value = this.value;

    })
    inputFrom.addEventListener("focus", function () {
        inputTo.checked = "checked";
        inputFrom.select();
    })


}


function changeStyle(newCss) {
    var styles = document.createElement('link');
    styles.type = "text/css";
    styles.rel = "stylesheet";
    styles.setAttribute("data-link", "cssAdd");
    styles.href = "/szablony/public/css/" + newCss + ".css";
    document.head.appendChild(styles);
}


function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

if (getCookie("contrast") != "") {
    let css = getCookie("contrast");

    if (document.querySelector(".settings__link[data-css='" + css + "']")) {
        let setLinksC = document.querySelectorAll(".settings__link[data-css='" + css + "']");
        for (i = 0; i < setLinksC.length; i++) {
            setLinksC[i].setAttribute("aria-pressed", "true");
        }
    }
}


let setLinks = document.querySelectorAll(".settings__link");

for (i = 0; i < setLinks.length; i++) {

    setLinks[i].addEventListener("click", function () {
        let btn = this;
        let group = this.getAttribute("data-group");
        let css = this.getAttribute("data-css");


        if (btn.getAttribute("aria-pressed") == "false") {
            if (group == "contrast") {
                btn.setAttribute("aria-pressed", "true");
                changeStyle(css);
                setCookie("contrast", css, 365);

            }
        } else {

            document.querySelector("head link[href*='" + css + "'][data-link='cssAdd']").remove();
            btn.setAttribute("aria-pressed", "false");
            if (group == "contrast") {
                document.cookie = "contrast=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            }


        }
    })
}


if (document.getElementById("scrollDown")) {
    document.getElementById("scrollDown").addEventListener("click", function (e) {
        e.preventDefault();
        window.scroll({
            top: 200,
            left: 0,
            behavior: 'smooth'
        });
    })

}


function playVideo(videoBox) {

    if (videoBox.querySelector("video")) {
        let video = videoBox.querySelector("video");
        let playBtn = videoBox.querySelector(".video__play");
        let title = videoBox.querySelector(".video__title");
        playBtn.addEventListener("click", function (e) {
            e.preventDefault();
            if (video) {
                video.play();
                title.style.display = "none";
                if (playBtn) {
                    playBtn.style.display = "none";
                }
            }

        })
    }


}

Array.prototype.map.call(document.querySelectorAll(".video__wrapper"), function (item) {
    playVideo(item)
});


if (document.getElementById("tabFilter")) {
    let filterTabsBtn = document.getElementById("tabFilter");

    let filterWrapper = document.querySelector(".tabMobileWrapper");
    filterTabsBtn.addEventListener("click", function () {
        if (filterTabsBtn.classList.contains("open")) {
            slideUp(filterWrapper);
            filterTabsBtn.classList.remove("open");
        } else {

            slideDown(filterWrapper);
            filterTabsBtn.classList.add("open");
        }
    })
}


if (document.querySelector(".manufature__btn")) {
    let btn = document.querySelector(".manufature__btn");
    let wrapper = document.getElementById("jsModal-film");

    let btnClose = wrapper.querySelector(".modal__close ");
    let iframe = document.querySelector("#ciasteczka-film");
    let srcNew = iframe.getAttribute("data-src");
    btn.addEventListener("click", function () {
        iframe.setAttribute("src", srcNew);
    })
    document.body.addEventListener('click', function (e) {
        let btn = e.target;
        if (btn.matches('#jsModal-film .modal__overlay')) {
            iframe.setAttribute("src", "");
        }
    });
    btnClose.addEventListener("click", function () {
        iframe.setAttribute("src", "");
    })
}


let number_format = function (number, decimals, dec_point, thousands_sep) {

    if (typeof (number) == "string") {
        number = parseFloat(number);
    }
    ;

    number = number.toFixed(decimals);

    var nstr = number.toString();
    nstr += '';
    let x = nstr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? dec_point + x[1] : '';
    var rgx = /(\d+)(\d{3})/;

    while (rgx.test(x1))
        x1 = x1.replace(rgx, '$1' + thousands_sep + '$2');

    return x1 + x2;
}

function changePrice(price) {
    if (price || price == 0) {
        return number_format(price, 2, ',', ' ')
    }
}

let sumAllProductInRightCart = 0;

function darmowaDostawaCallbackFunction(data, el) {
    let dostawa = JSON.parse(data)
    if (dostawa.darmowaDostawa) {
        if (dostawa.darmowaDostawa < sumAllProductInRightCart) {
            document.querySelector(".cart-free-shipping").style.display = "none";
            document.querySelector(".cart-free-shipping-active").style.display = "block";
        } else {
            document.querySelector(".cart-free-shipping").style.display = "block";
            document.querySelector(".cart-free-shipping-active").style.display = "none";
            document.querySelector("#cart-free-shipping__price").innerHTML = changePrice(dostawa.darmowaDostawa - sumAllProductInRightCart) + ' zł';
        }
    }
}

function podsumowanie(data) {

    let noRabat = document.querySelector("#header-cart-noRabat");
    let rabatInfo = document.querySelector(".header__cart-box .rabat");

    let podsumowanieData = JSON.parse(data);

    let cartIcon = document.querySelector("#headerControls__cartBtn");
    if (podsumowanieData.ilosc) {
        cartIcon.classList.add("is-active");
        document.querySelector("#cartAmount").innerHTML = podsumowanieData.ilosc;

    } else {
        if (cartIcon.classList.contains("is-active")) {
            cartIcon.classList.remove("is-active");
        }

    }
    document.querySelector("#header-cart-sum").innerHTML = changePrice(podsumowanieData.wartosc) + " zł";

    sumAllProductInRightCart = podsumowanieData.wartosc;

    if (podsumowanieData.rabat) {
        noRabat.innerText = changePrice(podsumowanieData.sumaKoszykPrzedRabatem);
        rabatInfo.style.display = "block";
        rabatInfo.querySelector("strong").innerText = podsumowanieData.rabat;
    } else {
        noRabat.innerText = "";
        rabatInfo.style.display = "none";
    }

    cartBoxOpen();

}

function summaryHeaderLisCallbackFunction(data, el) {

    podsumowanie(data);

    let data2 = new FormData();
    data2.set('a', "darmowa-dostawa");
    data2.set('__csrf__token__', token);
    makeRequest('/a/?a=darmowa-dostawa', "POST", data2, darmowaDostawaCallbackFunction, el);
}

function changeAmountCallbackFunction(data) {
    data2 = new FormData();
    data2.set('a', "podsumowanie]");
    data2.set('__csrf__token__', token);
    makeRequest('/a/?a=koszyk-podsumowanie', "POST", data2, summaryHeaderLisCallbackFunction);
}

function changeAmount(el) {
    let productId = el.closest(".product-data-id").getAttribute("data-id");
    let productIlosc = el.querySelector("input").value;
    let data2 = new FormData();
    // data2.set('a', "zmieńilos");
    // data2.set('ilosc', productIlosc);
    // data2.set('id', productId);
    data2.set('__csrf__token__', token);
    let href = '/a/?a=zmienIlosc&id=' + productId + '&ilosc=' + productIlosc;
    makeRequest(href, "POST", data2, changeAmountCallbackFunction, el)
}

function plusMinusRightCart(el) {

    el.querySelector(".plus").addEventListener('click', function (e) {
        e.preventDefault();
        let nr2 = parseInt(el.querySelector('input').value) + 1;
        el.querySelector('input').value = nr2;
        changeAmount(el);
    })
    el.querySelector(".min").addEventListener('click', function (e) {
        e.preventDefault();
        let nr3 = parseInt(el.querySelector('input').value) - 1;
        if (nr3 > 0) {
            el.querySelector('input').value = nr3
        }
        changeAmount(el);
    })
}

function changeValueInInput(input) {
    // input.addEventListener("keyup", function () {
    //     input.value = input.value.replace(/[^0-9]/g, '');
    // })
    input.addEventListener("change", function () {

        let qty = input.closest(".qty");
        changeAmount(qty);
    })
}

function createCartHeaderLisCallbackFunction(data, el) {
    if (data) {
        document.querySelector(".header__cart-sum").style.display = "block";
        document.querySelector(".btn--go-cart").style.display = "block";
        document.querySelector(".cart--empty").style.display = "none";
        let products = JSON.parse(data);
        document.querySelector(".header__cart-wrapper").innerHTML = "";
        for (i = 0; i < products.length; i++) {
            let product = products[i];

            let elLi = document.createElement("li");
            elLi.className = "product-data-id"
            elLi.setAttribute("data-id", product.id);

            let elA = document.createElement("div");
            elA.className = "header__cart-item";


            let elFigure = document.createElement("figure");
            let elImg = document.createElement("img");
            elImg.src = product.produkt_zdjecie;
            elImg.alt = "";

            let elWrapperRight = document.createElement("div");
            elWrapperRight.className = "header__cart-item-box";

            let elLink = document.createElement("a");
            elLink.href = product.url;
            elLink.innerText = product.produkt_nazwa;
            let elTitle = document.createElement("h3");
            elTitle.appendChild(elLink);

            if (product.personalizacja.length > 0) {
                let span = document.createElement("span");
                span.innerText = product.personalizacja;
                span.classList = "d-block font--10 font-weight-400";
                elTitle.appendChild(span);
            }

            let elWrapperBottom = document.createElement("div");
            elWrapperBottom.className = "header__cart-item-bottom";

            // let elSpan = document.createElement("span");
            // elSpan.innerHTML = product.ilosc + ' szt.';
            let elStrong = document.createElement("strong");
            elStrong.innerHTML = (product.produkt_cena_stara).toString().replace(".", ",") + ' zł';

            let elClose = document.createElement("button");
            elClose.type = "button";
            elClose.className = "btn--close btn-close--magenta";
            elClose.setAttribute("aria-label", "Usuń");
            if (product.personalizacja.length > 0) {
                elClose.setAttribute("data-personalizacja", product.personalizacja);
            }

            let plusminusWrapper = document.createElement("div");
            plusminusWrapper.className = "qty";
            let btnPlus = document.createElement("button");
            btnPlus.className = "plus qty__btn";
            btnPlus.innerText = "+";
            btnPlus.type = "button";
            if (product.personalizacja.length > 0) {
                btnPlus.setAttribute("data-personalizacja", product.personalizacja);
            }

            let btnMinus = document.createElement("button");
            btnMinus.className = "min qty__btn";
            btnMinus.innerText = "-";
            btnMinus.type = "button";
            if (product.personalizacja.length > 0) {
                btnMinus.setAttribute("data-personalizacja", product.personalizacja);
            }


            let inputNr = document.createElement("input");
            inputNr.value = product.ilosc;
            inputNr.type = "number";

            plusminusWrapper.appendChild(btnMinus);
            plusminusWrapper.appendChild(inputNr);
            plusminusWrapper.appendChild(btnPlus);


            elWrapperBottom.appendChild(plusminusWrapper);
            elWrapperBottom.appendChild(elStrong);
            elWrapperRight.appendChild(elTitle);
            elWrapperRight.appendChild(elWrapperBottom);
            plusMinusRightCart(plusminusWrapper);
            changeValueInInput(inputNr);

            elFigure.appendChild(elImg);
            elA.appendChild(elFigure);
            elA.appendChild(elWrapperRight);
            elLi.appendChild(elA);
            elLi.appendChild(elClose);
            document.querySelector(".header__cart-wrapper").appendChild(elLi);

            // let productHTML = '<li data-id="' + product.produkt + '"><a href="' + product.url + '"' +
            //     ' class="header__cart-item"><figure><img src="' + product.produkt_zdjecie + '" alt=""></figure><div class="header__cart-item-box"><h3>' +
            //     product.produkt_nazwa + '</h3><p><span>' + product.ilosc + ' szt.</span> <strong>' + (product.produkt_cena).toString().replace(".", ",") +
            //     ' zł</strong></p></div></a><button class="btn--close btn-close--magenta"></button></li>';
            // document.querySelector(".header__cart-wrapper").innerHTML = productHTML;
        }

    } else {
        document.querySelector(".header__cart-sum").style.display = "none";
        document.querySelector(".btn--go-cart").style.display = "none";
        if (document.querySelector(".cart--empty")) {
            document.querySelector(".cart--empty").style.display = "block";
        } else {
            document.querySelector(".cart--empty").style.display = "none";
        }
    }
    data2 = new FormData();
    data2.set('a', "podsumowanie]");
    data2.set('__csrf__token__', token);
    makeRequest('/a/?a=koszyk-podsumowanie', "POST", data2, summaryHeaderLisCallbackFunction, el);
}

function createCartHeaderList() {
    data = new FormData();
    data.set('a', "koszyk");
    data.set('__csrf__token__', token);

    let el = document.querySelector(".header__cart-wrapper");

    makeRequest('/a/?a=koszyk', "POST", data, createCartHeaderLisCallbackFunction, el);

}


document.body.addEventListener('click', function (e) {
    // Check if the clicked element matches the selector for the buttons
    if (e.target.matches('.product__item .btn--cart')) {
        e.preventDefault();
        e.stopPropagation();

        alertAddToCart.style.display = "block";

        let id = e.target.getAttribute("data-id")
        data = new FormData();
        data.set('a', "koszyk-dodaj");
        data.set('ilosc', "1");
        data.set('produkt', id);
        data.set('__csrf__token__', token);
        let el = document.querySelector(".header__cart-wrapper");
        makeRequest('/a/', "POST", data, createCartHeaderList, el);
    }

    if (e.target.matches('.p-card__form .btn--cart:not(.btn-personalizowany)')) {
        e.preventDefault();
        e.stopPropagation();
        alertAddToCart.style.display = "block";
        let id = e.target.getAttribute("data-id");
        let ilosc = document.getElementById("qty-1").value;
        data = new FormData();
        data.set('a', "koszyk-dodaj");
        data.set('ilosc', ilosc);
        data.set('produkt', id);
        if (document.querySelector("[name='personalizacja']"))
            data.set('personalizacja', id);
        data.set('__csrf__token__', token);
        let el = document.querySelector(".header__cart-wrapper");
        makeRequest('/a/', "POST", data, createCartHeaderList, el);
    }
});


function removeElemntCallbackFunction(data, item) {

    data2 = new FormData();
    data2.set('a', "podsumowanie]");
    data2.set('__csrf__token__', token);

    slideUp(item);
    setTimeout(item.remove(), 500);
    makeRequest('/a/?a=koszyk-podsumowanie', "POST", data2, summaryHeaderLisCallbackFunction);
}

document.body.addEventListener('click', function (e) {
    // Check if the clicked element matches the selector for the buttons


    let btn = e.target;
    if (btn.matches('.header__cart-wrapper .btn--close')) {
        let item = btn.closest(".product-data-id");
        let productId = item.getAttribute("data-id");
        slideUp(alertAddToCart);
        data = new FormData();
        data.set('a', "koszyk-usun");
        data.set('id', productId);
        data.set('__csrf__token__', token);
        if (btn.hasAttribute("data-personalizacja")) {
            data.set('personalizacja', btn.getAttribute("data-personalizacja"));
        }
        makeRequest('/a/', "POST", data, removeElemntCallbackFunction, item);
    }

})


if (document.querySelector(".btn-add-perso")) {
    let btnAdd = document.querySelector(".btn-add-perso");
    let div = document.querySelector(".personalizacja");
    let input = document.querySelector(".personalizacja input");
    btnAdd.addEventListener("click", function (e) {
        e.preventDefault();
        if (input.value.length == 0) {
            e.preventDefault();
            div.classList.add("error");
            input.focus();
        } else {
            input.value = (input.value).toUpperCase();
            btnAdd.closest("form").submit();
        }
    })

    let inputNr = document.getElementById("personalizacja-nr");

    let maxNr = inputNr.getAttribute("data-nr");

    input.addEventListener("input", function () {
        if (input.value.length == 0) {

            div.classList.add("error");
            input.focus();
        } else {
            if (div.classList.contains("error")) {
                div.classList.remove("error");
            }
        }
        let valLength = input.value.length;
        inputNr.innerText = parseInt(maxNr) - parseInt(valLength);
    })
}

if (document.querySelector(".personalizacja-acc")) {
    // let btnAcc = document.querySelector(".btn--acc");
    let elAcc = document.querySelectorAll(".personalizacja-nr");
    let boxAcc = document.querySelector(".personalizacja-acc");
    let inputsAll = document.querySelector('input[name="personalizacja"]');

    for (let i = 0; i < elAcc.length; i++) {
        let input = elAcc[i].querySelector("input");
        let span = elAcc[i].querySelector("span");
        let max = span.getAttribute("data-nr").replace("-", "");
        input.setAttribute("maxlength", max);
        span.setAttribute("data-nr", max);
        span.innerText = max;

        input.addEventListener("input", function () {
            let valLength = input.value.length;
            span.innerText = parseInt(max) - parseInt(valLength);
            if (document.getElementById("personalizacja2")) {
                inputsAll.value = (document.getElementById("personalizacja1").value + ", " + document.getElementById("personalizacja2").value).toUpperCase();
            } else {
                inputsAll.value = (document.getElementById("personalizacja1").value).toUpperCase();
            }
        })
    }

    // btnAcc.addEventListener("click", function () {
    //     if (btnAcc.classList.contains("open")) {
    //         btnAcc.classList.remove("open");
    //         slideUp(boxAcc);
    //         inputsAll.value = "";
    //     } else {
    //         btnAcc.classList.add("open");
    //         slideDown(boxAcc);
    //         inputsAll.value = document.getElementById("personalizacja1").value + ", " + document.getElementById("personalizacja2").value;
    //     }
    // })

}
//
// if (document.querySelector(".btn-personalizowany")) {
//     console.log("test2");
//     let btn = document.querySelector(".btn-personalizowany");
//     btn.addEventListener("click", function (e) {
//         e.preventDefault();
//         e.stopPropagation();
//         alertAddToCart.style.display = "block";
//         let id = e.target.getAttribute("data-id");
//         let ilosc = document.getElementById("qty-1").value;
//         let inputsAll = document.querySelector('input[name="personalizacja"]');
//         data = new FormData();
//         data.set('a', "koszyk-dodaj");
//         data.set('ilosc', ilosc);
//         data.set('produkt', id);
//         data.set('personalizacja', inputsAll.value);
//         data.set('__csrf__token__', token);
//         let el = document.querySelector(".header__cart-wrapper");
//         makeRequest('/a/', "POST", data, createCartHeaderList, el);
//     })
// }


if (document.querySelector(".glightbox-wrapper")) {

    let lightboxWrapper = document.querySelectorAll(".glightbox-wrapper");

    for (let i = 0; i < lightboxWrapper.length; i++) {
        let selectorName = "#" + lightboxWrapper[i].getAttribute("id") + " [data-glightbox]";
        let lightbox = GLightbox({
            selector: selectorName,
            touchNavigation: true,
        });
        let btnSelector = ".ig__btn[data-href=" + lightboxWrapper[i].getAttribute("id") + "]";
        if (document.querySelector(btnSelector)) {
            document.querySelector(btnSelector).addEventListener("click", function (e) {
                e.preventDefault();
                lightbox.open();
            })
        }
    }
}


if (document.querySelector(".material__zoom")) {
    console.log("zzz");
    const lightbox = GLightbox({
        selector: '.material__zoom[data-lightbox="skorki"]' // Lightbox dla obrazów z atrybutem data-lightbox="skorki"
    });

}


//pompon

function removeClass(item, nazwaKlasy) {
    if (item.classList.contains(nazwaKlasy)) {
        item.classList.remove(nazwaKlasy);
    }
}


function accordionInput(wrapper) {
    console.log(wrapper);
    let input = wrapper.querySelector(".ia__input");
    let content = wrapper.querySelector(".ia__content");

    input.addEventListener("change", function () {
        if (input.checked) {
            slideDown(content);
        } else {
            slideUp(content);
        }
    })
}

Array.prototype.map.call(document.querySelectorAll(".ia__wrapper"), function (item) {
    accordionInput(item);
});


if (document.getElementById("wizualizacja2")) {
    //pompon
    let inputKolorIle = document.querySelectorAll(".input-pompon-kolor-nr");

    let pomponImg1 = document.getElementById("wizualizacja-pompon-1");
    let pomponImg2 = document.getElementById("wizualizacja-pompon-2");
    let pomponImg3 = document.getElementById("wizualizacja-pompon-3");

    let inputWybierzKolor1 = document.getElementById("pompon-wk-1");
    let inputWybierzKolor2 = document.getElementById("pompon-wk-2");
    let inputWybierzKolor3 = document.getElementById("pompon-wk-3");

    let inputWybierzKolorItem1 = document.getElementById("pompon-wybierzkolor-1");
    let inputWybierzKolorItem2 = document.getElementById("pompon-wybierzkolor-2");
    let inputWybierzKolorItem3 = document.getElementById("pompon-wybierzkolor-3");

    let pomponNitki11 = document.getElementById("wizualizacja-p1-1");
    let pomponNitki21 = document.getElementById("wizualizacja-p2-1");
    let pomponNitki22 = document.getElementById("wizualizacja-p2-2");
    let pomponNitki31 = document.getElementById("wizualizacja-p3-1");
    let pomponNitki32 = document.getElementById("wizualizacja-p3-2");
    let pomponNitki33 = document.getElementById("wizualizacja-p3-3");

    let pomponWrapper = document.getElementById("wizualizacja-pompon-wrapper");
    let pomponWrapperCheckbox = document.getElementById("checkbox-pompon");

    function onOffPompon() {
        if (pomponWrapperCheckbox.checked) {
            removeClass(pomponWrapper, "hide");
        } else {
            pomponWrapper.classList.add("hide");
        }
    }

    onOffPompon();
    pomponWrapperCheckbox.addEventListener("change", onOffPompon);

    function ukryjWybieranieKoloru(input) {
        let nr = input.getAttribute("data-kolor");

        if (nr == "1") {
            inputWybierzKolorItem2.classList.add("hide");
            inputWybierzKolorItem3.classList.add("hide");
            pomponImg1.classList.add("zi-10");
            removeClass(pomponImg2, "zi-10");
            removeClass(pomponImg3, "zi-10");
        }
        if (nr == "2") {
            inputWybierzKolorItem3.classList.add("hide");
            removeClass(inputWybierzKolorItem2, "hide");

            pomponImg2.classList.add("zi-10");
            removeClass(pomponImg1, "zi-10");
            removeClass(pomponImg3, "zi-10");
        }
        if (nr == "3") {
            removeClass(inputWybierzKolorItem2, "hide");
            removeClass(inputWybierzKolorItem3, "hide");
            pomponImg3.classList.add("zi-10");
            removeClass(pomponImg1, "zi-10");
            removeClass(pomponImg2, "zi-10");

        }
    }

    for (let i = 0; i < inputKolorIle.length; i++) {
        inputKolorIle[i].addEventListener("change", function () {
            ukryjWybieranieKoloru(document.querySelector(".input-pompon-kolor-nr:checked"));
        })
    }

    function kolorowanieNitek(input, item) {
        let kolor = input.value;
        item.style.fill = kolor;
    }

    function kolorowanieNitekW1(input) {
        kolorowanieNitek(input, pomponNitki11);
        kolorowanieNitek(input, pomponNitki21);
        kolorowanieNitek(input, pomponNitki31);
    }

    function kolorowanieNitekW2(input) {
        kolorowanieNitek(input, pomponNitki22);
        kolorowanieNitek(input, pomponNitki32);
    }

    function kolorowanieNitekW3(input) {
        kolorowanieNitek(input, pomponNitki33);
    }

    kolorowanieNitekW1(inputWybierzKolor1);
    kolorowanieNitekW2(inputWybierzKolor2);
    kolorowanieNitekW3(inputWybierzKolor3);

    inputWybierzKolor1.addEventListener("change", function () {
        let input = this;
        kolorowanieNitekW1(input)
    })
    inputWybierzKolor2.addEventListener("change", function () {
        let input = this;
        kolorowanieNitekW2(input)
    })
    inputWybierzKolor3.addEventListener("change", function () {
        let input = this;
        kolorowanieNitekW3(input)
    })


    //gora

    //pompon
    let inputPietroKolorIle = document.querySelectorAll(".input-pietro-kolor-nr");

    let pietroImg1 = document.getElementById("wizualizacja-pietro-1");
    let pietroImg2 = document.getElementById("wizualizacja-pietro-2");
    let pietroImg3 = document.getElementById("wizualizacja-pietro-3");

    let pasekImg21 = document.getElementById("wizualizacja-pietro-2-pasek");
    let pasekImg31 = document.getElementById("wizualizacja-pietro-3-pasek-1");
    let pasekImg32 = document.getElementById("wizualizacja-pietro-3-pasek-2");

    let pietroImgKolor21 = document.getElementById("wizualizacja-pietro-2-1");
    let pietroImgKolor22 = document.getElementById("wizualizacja-pietro-2-2");
    let pietroImgKolor31 = document.getElementById("wizualizacja-pietro-3-1");
    let pietroImgKolor32 = document.getElementById("wizualizacja-pietro-3-2");
    let pietroImgKolor33 = document.getElementById("wizualizacja-pietro-3-3");

    let inputWybierzKolorPietro1 = document.getElementById("pietro-wk-1");
    let inputWybierzKolorPietro2 = document.getElementById("pietro-wk-2");
    let inputWybierzKolorPietro3 = document.getElementById("pietro-wk-3");

    let inputWybierzKolorPietroItem1 = document.getElementById("pietro-wybierzkolor-1");
    let inputWybierzKolorPietroItem2 = document.getElementById("pietro-wybierzkolor-2");
    let inputWybierzKolorPietroItem3 = document.getElementById("pietro-wybierzkolor-3");


    let pasekKolorInput1 = document.getElementById("pasek-wk-1");
    let pasekKolorInput2 = document.getElementById("pasek-wk-2");

    let pasekKolorWrapper1 = document.getElementById("pasek-1-wrapper");
    let pasekKolorWrapper2 = document.getElementById("pasek-2-wrapper");

    let pasekKolorItem1 = document.getElementById("pasek-wybierzkolor-1");
    let pasekKolorItem2 = document.getElementById("pasek-wybierzkolor-2");

    let pasek1Checkbox = document.getElementById("pasek-1");
    let pasek2Checkbox = document.getElementById("pasek-2");


    let napisImg = document.getElementById("wizualizacja-napis");
    let dodajpasekH3 = document.getElementById("dodaj-pasek-h3");

    function checkPasek1() {

        if (pasek1Checkbox.checked) {
            removeClass(pasekKolorItem1, "hide");
            kolorowanieNitek(pasekKolorInput1, pasekImg21);
            kolorowanieNitek(pasekKolorInput1, pasekImg31);
        } else {
            pasekKolorItem1.classList.add("hide");
            kolorowanieNitek(inputWybierzKolorPietro1, pasekImg21);
            kolorowanieNitek(inputWybierzKolorPietro1, pasekImg31);
        }
    }

    function checkPasek2(input) {
        if (pasek2Checkbox.checked) {
            removeClass(pasekKolorItem2, "hide");
            kolorowanieNitek(pasekKolorInput2, pasekImg32);
        } else {
            pasekKolorItem2.classList.add("hide");
            kolorowanieNitek(inputWybierzKolorPietro3, pasekImg32);
        }
    }

    pasek1Checkbox.addEventListener("change", function () {
        checkPasek1();
    });
    pasek2Checkbox.addEventListener("change", function () {
        checkPasek2();
    })


    function ukryjWybieraniePietro(input) {
        let nr = input.getAttribute("data-kolor");

        if (nr == "1") {
            removeClass(pietroImg1, "hide");
            pietroImg2.classList.add("hide");
            pietroImg3.classList.add("hide");

            inputWybierzKolorPietroItem2.classList.add("hide");
            inputWybierzKolorPietroItem3.classList.add("hide");

            slideUp(pasekKolorWrapper1);
            slideUp(pasekKolorWrapper2);

            slideUp(dodajpasekH3);

            removeClass(napisImg, "wizualizacja-napis-2");
        }

        if (nr == "2") {
            removeClass(pietroImg2, "hide");
            pietroImg1.classList.add("hide");
            pietroImg3.classList.add("hide");

            removeClass(inputWybierzKolorPietroItem2, "hide");
            inputWybierzKolorPietroItem3.classList.add("hide");

            if (pasekKolorWrapper1.style.display != "flex") {
                slideDown(pasekKolorWrapper1);
            }
            slideUp(pasekKolorWrapper2);

            checkPasek1();

            napisImg.classList.add("wizualizacja-napis-2");
            dodajpasekH3.style.display = "block";

        }

        if (nr == "3") {
            removeClass(pietroImg3, "hide");
            pietroImg2.classList.add("hide");
            pietroImg1.classList.add("hide");

            removeClass(inputWybierzKolorPietroItem2, "hide");
            removeClass(inputWybierzKolorPietroItem3, "hide");

            if (pasekKolorWrapper1.style.display != "flex") {
                slideDown(pasekKolorWrapper1);
            }
            slideDown(pasekKolorWrapper2);

            checkPasek1();
            checkPasek2()

            removeClass(napisImg, "wizualizacja-napis-2");
            dodajpasekH3.style.display = "block";
        }
    }

    for (let i = 0; i < inputPietroKolorIle.length; i++) {
        inputPietroKolorIle[i].addEventListener("change", function () {
            ukryjWybieraniePietro(document.querySelector(".input-pietro-kolor-nr:checked"));
        })
    }


    function kolorowaniePietraW1(input) {
        kolorowanieNitek(input, pietroImg1);
        kolorowanieNitek(input, pietroImgKolor21);
        kolorowanieNitek(input, pietroImgKolor31);
    }

    function kolorowaniePietraW2(input) {
        kolorowanieNitek(input, pietroImgKolor22);
        kolorowanieNitek(input, pietroImgKolor32);
    }

    function kolorowaniePietraW3(input) {
        kolorowanieNitek(input, pietroImgKolor33);
    }

    function kolorowaniePaska1(input) {
        kolorowanieNitek(input, pasekImg21);
        kolorowanieNitek(input, pasekImg31);
    }

    function kolorowaniePaska2(input) {
        kolorowanieNitek(input, pasekImg32);
    }

    kolorowaniePietraW1(inputWybierzKolorPietro1);
    kolorowaniePietraW2(inputWybierzKolorPietro2);
    kolorowaniePietraW3(inputWybierzKolorPietro3);
    kolorowaniePaska1(pasekKolorInput1);
    kolorowaniePaska2(pasekKolorInput2);

    inputWybierzKolorPietro1.addEventListener("change", function () {
        let input = this;
        kolorowaniePietraW1(input)
    })
    inputWybierzKolorPietro2.addEventListener("change", function () {
        let input = this;
        kolorowaniePietraW2(input)
    })
    inputWybierzKolorPietro3.addEventListener("change", function () {
        let input = this;
        kolorowaniePietraW3(input)
    })
    pasekKolorInput1.addEventListener("change", function () {
        let input = this;
        kolorowaniePaska1(input)
    })
    pasekKolorInput2.addEventListener("change", function () {
        let input = this;
        kolorowaniePaska2(input);
    })


    //napis


    let napisInputKolor = document.getElementById("napis-kolor");
    let napisCheckBox = document.getElementById("checkbox-napis");
    let napisTresc = document.getElementById("napis-tresc");
    let napisCzcionka = document.getElementById("napis-czcionka");

    function onOffNapis() {
        if (napisCheckBox.checked) {
            removeClass(napisImg, "hide");
        } else {
            napisImg.classList.add("hide");
        }
    }

    onOffNapis();

    napisCheckBox.addEventListener("change", function () {
        onOffNapis();
    })

    function czcionkaNapis(input) {
        napisImg.style.fontFamily = input.value;
    }

    czcionkaNapis(napisCzcionka);
    napisCzcionka.addEventListener("change", function () {
        let input = this;
        czcionkaNapis(input);
    })

    function trescNapis(input) {
        napisImg.innerHTML = input.value;
    }

    trescNapis(napisTresc);
    napisTresc.addEventListener("input", function () {
        let input = this;
        trescNapis(input);
    })

    function kolorowanieNapis(input) {
        napisImg.style.color = napisInputKolor.value;
    }

    kolorowanieNapis(napisInputKolor);

    napisInputKolor.addEventListener("change", function () {
        let input = this;
        kolorowanieNapis(input);
    })


    //wywiniecie
    let wywiniecieImg = document.getElementById("wizualizacja-wywiniecie");
    let wywiniecieInputKolor = document.getElementById("wywiniecie-wk");


    function kolorowanieWywiniecie(input) {
        kolorowanieNitek(input, wywiniecieImg);
    }

    kolorowanieWywiniecie(wywiniecieInputKolor);
    wywiniecieInputKolor.addEventListener("change", function () {
        let input = this;
        kolorowanieWywiniecie(input);
    })


    //podszewka
    let podszewkaImg = document.getElementById("wizualizacja-podszewka");
    let podszewkaInputKolor = document.getElementById("podszewka-wk");

    function kolorowaniePodszewki(input) {
        kolorowanieNitek(input, podszewkaImg);
    }

    kolorowaniePodszewki(podszewkaInputKolor);
    podszewkaInputKolor.addEventListener("change", function () {
        let input = this;
        kolorowaniePodszewki(input);
    })


}
